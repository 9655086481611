import {
  Schema,
  APISchemaDefinition,
  APISchemaDefinitionMetric,
  SchemaSubGroup,
  SchemaMetric,
  SchemaMetricGroup,
} from './types';
import { splitMetricFilterGroup } from './helpers';
import { MetricSummary } from '../metrics/types';
import { MetricDetails } from '../metric/types';

/**
 * Converts the API's defined schema response (APISchemaDefinition) into the format
 * used by the frontend (Schema) which divides the metrics into two groups:
 * 'sub-groups' and 'general' based on the metric filter groups.
 * @param schema (type: APISchemaDefinition)
 * @returns (type: Schema)
 */
export function transformAPISchemaDefinition(schema: APISchemaDefinition): Schema {
  function transformMetrics(metrics: APISchemaDefinitionMetric[]) {
    return metrics.map((metric: any) => ({
      fin: metric.fin,
      type: metric.type,
      isRequired: metric.isRequired,
      displayName: metric.displayName,
      description: metric.description,
      validators: metric.validators ?? [],
      associatedEntityType: metric.associatedEntityType,
      associatedRelationshipEntityTypes: metric.associatedRelationshipEntityTypes,
      isStatic: metric.isStatic,
      timePeriod: metric.timePeriod,
      metricSources: metric.metricSources,
    }));
  }

  function getSubGroups(
    metrics: APISchemaDefinitionMetric[],
  ): SchemaSubGroup[] {
    const subGroupMetricsByName: { [key: string]: APISchemaDefinitionMetric[] } = {};
    metrics.forEach((metric) => {
      const subGroupDisplayName = splitMetricFilterGroup(metric.filterGroup ?? '').subGroupName;
      const isMetricInASubGroup = !!subGroupDisplayName;
      // Add the metric to its subgroup's mapping
      if (isMetricInASubGroup) {
        if (subGroupDisplayName in subGroupMetricsByName) {
          subGroupMetricsByName[subGroupDisplayName].push(metric);
        } else {
          // Create the mapping entry for the metric's subgroup if it hasn't been created yet
          subGroupMetricsByName[subGroupDisplayName] = [metric];
        }
      }
    });

    return Object.keys(subGroupMetricsByName)
      .map((name) => ({
        displayName: name,
        metrics: transformMetrics(subGroupMetricsByName[name]),
        enableTimePeriodFilter: subGroupMetricsByName[name].some((metric) => !!(splitMetricFilterGroup(metric.filterGroup ?? '').timePeriod)),
      }));
  }

  return {
    documentSchemaId: schema.documentSchemaId,
    name: schema.name,
    description: schema.description,
    isTemplate: schema.isTemplate,
    isPublished: schema.isPublished,
    reportingEntityType: schema.reportingEntityType,
    versionedDocumentTypes: schema.versionedDocumentTypes,
    groups: schema.groups.map((group) => ({
      groupIdentifier: group.groupIdentifier,
      displayName: group.displayName,
      isRepeatingGroup: group.isRepeatingGroup,
      calculatedTotals: group.calculatedTotals,
      generalMetrics: transformMetrics(
        group.metrics.filter((metric) => !metric.filterGroup || metric.filterGroup === ''),
      ),
      subGroups: getSubGroups(group.metrics),
    })),
  };
}

export function transformSchema(schema: Schema): APISchemaDefinition {
  function transformMetrics({
    metrics,
    subGroupDisplayName,
    enableTimePeriodFilter = false,
    isPinnedMetrics = false,
  }: {
    metrics: SchemaMetric[],
    subGroupDisplayName?: string,
    enableTimePeriodFilter?: boolean,
    isPinnedMetrics?: boolean,
  }): APISchemaDefinitionMetric[] {
    let primaryFilterGroup: string | null = null;

    if (subGroupDisplayName) {
      primaryFilterGroup = subGroupDisplayName;
    }

    if (isPinnedMetrics) {
      primaryFilterGroup = '';
    }

    return metrics.map((metric) => {
      const timePeriodFilter = enableTimePeriodFilter ? metric.timePeriod?.name : null;
      const filterGroup = primaryFilterGroup && timePeriodFilter ? primaryFilterGroup.concat(`//Time Period//${timePeriodFilter}`) : primaryFilterGroup;
      return {
        fin: metric.fin,
        type: metric.type,
        isRequired: metric.isRequired,
        displayName: metric.displayName,
        description: metric.description,
        metricSources: metric.metricSources,
        ...filterGroup !== null && { filterGroup },
        timePeriod: metric.timePeriod,
        validators: metric.validators,
        isStatic: metric.isStatic,
        associatedEntityType: metric.associatedEntityType,
        associatedRelationshipEntityTypes: metric.associatedRelationshipEntityTypes,
      };
    });
  }

  const apiSchemaDefinition: APISchemaDefinition = {
    documentSchemaId: schema.documentSchemaId,
    name: schema.name,
    description: schema.description,
    isTemplate: schema.isTemplate,
    isPublished: schema.isPublished,
    reportingEntityType: schema.reportingEntityType,
    versionedDocumentTypes: schema.versionedDocumentTypes,
    groups: [],
  };

  const schemaGroups = schema.groups;
  schemaGroups.forEach((metricGroup: SchemaMetricGroup) => {
    const generalMetrics = transformMetrics({
      metrics: metricGroup.generalMetrics,
      isPinnedMetrics: metricGroup.subGroups.length > 0,
    });

    const subGroupMetrics = metricGroup.subGroups.map(
      (subGroup) => transformMetrics({
        metrics: subGroup.metrics,
        enableTimePeriodFilter: subGroup.enableTimePeriodFilter,
        subGroupDisplayName: subGroup.displayName,
      }),
    ).flat(1);

    apiSchemaDefinition.groups.push({
      groupIdentifier: metricGroup.groupIdentifier,
      displayName: metricGroup.displayName,
      isRepeatingGroup: metricGroup.isRepeatingGroup,
      calculatedTotals: metricGroup.calculatedTotals,
      metrics: [
        ...generalMetrics,
        ...subGroupMetrics,
      ],
    });
  });
  return apiSchemaDefinition;
}

/**
 * Converts a metric summary into a schema-builder compatible definition.
 * @param metric (type: Metric)
 * @returns (type: SchemaMetric)
 */
export function transformMetricToSchemaMetric(metric: MetricSummary): SchemaMetric {
  return ({
    fin: metric.fin,
    isRequired: false,
    type: metric.type,
    displayName: metric.name,
    description: metric.description,
    timePeriod: metric.timePeriod,
    isStatic: metric.isStatic,
    validators: metric.validators,
    associatedEntityType: metric.associatedEntityType,
    associatedRelationshipEntityTypes: metric.associatedRelationshipEntityTypes,
    metricSources: metric.metricSources,
  });
}

export function transformMetricDetailsToSchemaMetric(metric: MetricDetails): SchemaMetric {
  return ({
    fin: metric.fin,
    isRequired: false,
    type: metric.type,
    displayName: metric.name,
    description: metric.description,
    timePeriod: metric.timePeriod,
    isStatic: metric.isStatic,
    validators: metric.validators,
    associatedEntityType: metric.associatedEntityType,
    associatedRelationshipEntityTypes: metric.associatedRelationshipEntityTypes,
    metricSources: metric.metricSources,
  });
}

export function transformSchemaMetricToMetricDetails(metric: SchemaMetric): MetricDetails {
  return {
    fin: metric.fin,
    type: metric.type,
    name: metric.displayName,
    description: metric.description ?? '',
    timePeriod: metric.timePeriod,
    isStatic: metric.isStatic,
    validators: metric.validators,
    associatedEntityType: metric.associatedEntityType,
    associatedRelationshipEntityTypes: metric.associatedRelationshipEntityTypes,
    metricSources: metric.metricSources,
  };
}
