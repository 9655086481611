import { MetricDataTypeMappings } from './types';

const columnHeaders = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'description',
    label: 'Description',
  },
  {
    id: 'createdBy',
    label: 'Created By',
  },
  {
    id: 'association',
    label: 'Association',
  },
];

const DEFAULT_MAX_PAGE_SIZE = 20;

const DATA_TYPE_MAPPINGS: MetricDataTypeMappings = {
  STRING: {
    label: 'Text',
  },
  NUMERIC: {
    label: 'Numeric',
  },
  PERCENTAGE: {
    label: 'Percentage',
  },
  CURRENCY: {
    label: 'Currency',
  },
  DATE: {
    label: 'Date',
  },
  ENTITY_NAME: {
    label: 'Entity Type',
  },
  ENTITY_LINK: {
    label: 'Entity Link',
  },
};

export { columnHeaders, DEFAULT_MAX_PAGE_SIZE, DATA_TYPE_MAPPINGS };
